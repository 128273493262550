import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createLevel, deleteLevel, 
//   createBook,
// createExpense,
// deleteOneExpense,
getAllLevels, getOneLevel, updateLevel,
// getExpenses,
// getOneExpense,
// updateExpense,
 } from './actions';
var initialState = {
    levels: { docs: [], meta: {} },
    level: null,
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'levels',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        // CREATE ONE
        builder
            .addCase(createLevel.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createLevel.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.levels = action.payload.data;
        })
            .addCase(createLevel.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // UPDATE ONE
        builder
            .addCase(updateLevel.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateLevel.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.levels = action.payload.data;
        })
            .addCase(updateLevel.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ALL
        builder
            .addCase(getAllLevels.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllLevels.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.levels = action.payload;
        })
            .addCase(getAllLevels.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ONE
        builder
            .addCase(getOneLevel.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneLevel.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.level = action.payload.data;
        })
            .addCase(getOneLevel.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // DELETE ONE
        builder
            .addCase(deleteLevel.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteLevel.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.levels.docs = state.levels.docs.filter(function (level) { return level._id !== action.meta.arg; });
        })
            .addCase(deleteLevel.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
